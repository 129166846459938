import { GlobalToken, theme } from 'antd';
import { TFunction } from 'i18next';
import { ReactElement, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityInfoContext, activityOverflowCount } from './providers/ActivityInfoProvider';
import { HostThemeConfigContext } from './providers/theme/HostThemeConfigProvider';

const { useToken } = theme;

export interface PageTitleAndIconUpdaterProps {
  pageTitle?: string | ((t: TFunction<'translation'>) => string);
}

export default function PageTitleAndIconUpdater({ pageTitle }: PageTitleAndIconUpdaterProps): ReactElement {
  const hostThemeConfig = useContext(HostThemeConfigContext);
  const { totalActivityCount } = useContext(ActivityInfoContext) ?? { totalActivityCount: 0 }; // defaulting is necessary for the logged-out page

  const { t } = useTranslation();
  const { token } = useToken();

  useEffect(
    () => {
      if (!hostThemeConfig)
        return;

      const title = pageTitle ? `${typeof pageTitle === 'function' ? pageTitle(t) : pageTitle} - ${hostThemeConfig.appName}` : hostThemeConfig.appName;
      const titleWithActivity = totalActivityCount > 0 ? `(${limitOverflow(totalActivityCount, activityOverflowCount)}) ${title}` : title;
      document.title = titleWithActivity;

      const favicon = document.querySelector('link[rel="icon"]') as HTMLLinkElement;
      favicon.href = hostThemeConfig.faviconUrl;

      if (totalActivityCount > 0)
        addBadgeToFavicon(favicon, hostThemeConfig.faviconUrl, token);
    },
    [hostThemeConfig, pageTitle, t, token, token.colorError, totalActivityCount],
  );

  return <></>;
}

function addBadgeToFavicon(favicon: HTMLLinkElement, iconUrl: string, token: GlobalToken) {
  const faviconSize = 32;
  const badgeSize = faviconSize / 5;
  const canvas = document.createElement('canvas');

  canvas.width = faviconSize;
  canvas.height = faviconSize;

  const context = canvas.getContext('2d');
  const img = document.createElement('img');

  function createBadge() {
    if (context === null)
      return;

    context.drawImage(img, 0, 0, faviconSize, faviconSize);

    context.beginPath();
    context.arc(canvas.width - badgeSize, badgeSize, badgeSize, 0, 2 * Math.PI);
    context.fillStyle = token.colorError;
    context.fill();

    // if we want to add a number to the badge
    // context.font = '15px Arial, sans-serif';
    // context.textAlign = 'center';
    // context.textBaseline = 'middle';
    // context.fillStyle = '#fff';
    // context.fillText('n', canvas.width - badgeSize, badgeSize);

    favicon.href = canvas.toDataURL('image/png');
  }

  img.addEventListener('load', createBadge);
  img.setAttribute('crossorigin', 'anonymous');
  img.src = iconUrl;
}

function limitOverflow(value: number, overflowCount: number): string {
  return value > overflowCount ? `${overflowCount}+` : value.toString();
}