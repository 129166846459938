import { ThemeConfig, theme } from 'antd';
import { colorToThemeTokens } from './utils/antDesignUtils';

type AppThemeConfig = Required<Pick<ThemeConfig, 'token' | 'algorithm'>>;

export interface AppThemeSet {
  light: AppThemeConfig;
  dark: AppThemeConfig;
}

export const buzzeasyThemeColor = '#326ec2';
export const buzzeasyDefaultFont = `NunitoSans, ${theme.defaultSeed.fontFamily}`;
export const buzzeasyDefaultCodeFont = `Consolas, ${theme.defaultSeed.fontFamilyCode}`;

const appTheme: AppThemeSet = {
  light: {
    token: {
      fontFamily: buzzeasyDefaultFont,
      fontFamilyCode: buzzeasyDefaultCodeFont,
      ...colorToThemeTokens('primary', buzzeasyThemeColor),
      ...colorToThemeTokens('info', buzzeasyThemeColor),
    },
    algorithm: theme.defaultAlgorithm,
  },
  dark: {
    token: {
      fontFamily: buzzeasyDefaultFont,
      fontFamilyCode: buzzeasyDefaultCodeFont,
      colorPrimary: buzzeasyThemeColor,
      colorInfo: buzzeasyThemeColor,
    },
    algorithm: theme.darkAlgorithm,
  },
};

export default appTheme;