import { GlobalToken, theme } from 'antd';
import { PropsWithChildren, ReactElement, useContext, useMemo } from 'react';
import { ThemeProvider } from 'styled-components';
import { getSystemColorScheme } from '../../../utils/cssUtils';
import { SettingsContext } from '../SettingsProvider';

export interface StyledComponentsTheme extends GlobalToken {
  colorScheme: 'light' | 'dark';
}

export default function StyledThemeProvider({ children }: PropsWithChildren): ReactElement {
  const { token } = theme.useToken();
  const [{ colorScheme }] = useContext(SettingsContext);

  const tokensWithColorScheme = useMemo<StyledComponentsTheme>(
    () => ({
      ...token,
      colorScheme: colorScheme ?? getSystemColorScheme(),
    }),
    [colorScheme, token],
  );

  return (
    <ThemeProvider theme={tokensWithColorScheme}>
      {children}
    </ThemeProvider>
  );
}