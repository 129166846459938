import { Locale } from 'antd/es/locale';
import antDeDE from 'antd/lib/locale/de_DE';
import antEnUS from 'antd/lib/locale/en_US';
import antEsES from 'antd/lib/locale/es_ES';
import antFrFR from 'antd/lib/locale/fr_FR';
import antHuHU from 'antd/lib/locale/hu_HU';
import antItIT from 'antd/lib/locale/it_IT';
import antNlNL from 'antd/lib/locale/nl_NL';
import antRoRO from 'antd/lib/locale/ro_RO';
import antTrTR from 'antd/lib/locale/tr_TR';
import deDE from './de-DE.json';
import enUS from './en-US.json';
import esES from './es-ES.json';
import frFR from './fr-FR.json';
import huHU from './hu-HU.json';
import itIT from './it-IT.json';
import nlNL from './nl-NL.json';
import roRO from './ro-RO.json';
import trTR from './tr-TR.json';

export interface Language {
  translation: {
    meta: {
      languageInEnglish: string;
      languageInNative: string;
    };
  };
}

export const translations: Record<string, Language> = {
  'en-US': enUS,
  'hu-HU': huHU,
  'ro-RO': roRO,
  'de-DE': deDE,
  'es-ES': esES,
  'fr-FR': frFR,
  'it-IT': itIT,
  'nl-NL': nlNL,
  'tr-TR': trTR,
};

export const antLocales: Record<string, Locale> = {
  'en-US': antEnUS,
  'hu-HU': antHuHU,
  'ro-RO': antRoRO,
  'de-DE': antDeDE,
  'es-ES': antEsES,
  'fr-FR': antFrFR,
  'it-IT': antItIT,
  'nl-NL': antNlNL,
  'tr-TR': antTrTR,
};