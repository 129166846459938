
const appPrefix = 'aui';

type ClassNameValue = string | boolean | null | undefined;

export function joinClassNames(...classNames: ClassNameValue[]): string {
  return classNames.filter(Boolean).join(' ');
}

function getNameFromComponent(components: string | string[] | Record<string, unknown>): string {
  if (typeof components === 'string')
    return components;

  if (components instanceof Array)
    return components.join('_');

  return Object.keys(components).join('_');
}

export function getClassNameForComponent(components: string | string[] | Record<string, unknown>, extraClassNames?: string): string {
  const componentName = `${appPrefix}-${getNameFromComponent(components)}`;

  return extraClassNames
    ? joinClassNames(componentName, extraClassNames)
    : componentName;
}

export function hashString(str: string): number {
  let hash = 0;

  for (let i = 0; i < str.length; i++)
    hash = Math.imul(31, hash) + str.charCodeAt(i) | 0;

  return hash;
}