import { Settings } from '../components/providers/SettingsProvider';

export type CssSize = string | number;

export function normalizeCssSize(value: CssSize): string {
  return typeof value === 'number' ? `${value}px` : value;
}

export function getSystemColorScheme(): NonNullable<Settings['colorScheme']> {
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)
    return 'dark';
  else
    return 'light';
}