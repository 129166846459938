import { ConfigProvider, ThemeConfig, theme } from 'antd';
import { PropsWithChildren, ReactElement, createContext, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import appTheme, { AppThemeSet, buzzeasyDefaultFont, buzzeasyThemeColor } from '../../../appTheme';
import { antLocales } from '../../../translations';
import { colorToThemeTokens } from '../../../utils/antDesignUtils';
import { getSystemColorScheme } from '../../../utils/cssUtils';
import { Settings, SettingsContext } from '../SettingsProvider';
import { HostThemeConfigContext } from './HostThemeConfigProvider';

let _colorScheme = getSystemColorScheme();

/**
 * Function to get the current color scheme without the need to use the context.
 */
export function getColorScheme(): NonNullable<Settings['colorScheme']> {
  return _colorScheme;
}

export const AntDesignConfigContext = createContext<ThemeConfig>(appTheme.light);

export default function AntDesignThemeProvider({ children }: PropsWithChildren): ReactElement {
  const [{ colorScheme }] = useContext(SettingsContext);
  const hostThemeConfig = useContext(HostThemeConfigContext);
  const { i18n } = useTranslation();

  const actualColorScheme = useMemo(
    () => colorScheme ?? getSystemColorScheme(),
    [colorScheme],
  );

  _colorScheme = actualColorScheme;

  const hostThemeSet = useMemo<AppThemeSet | null>(
    () => {
      if (!hostThemeConfig)
        return null;

      return {
        light: {
          token: {
            ...hostThemeConfig.theme.light,
            fontFamily: hostThemeConfig.theme.light.fontFamily
              ?? buzzeasyDefaultFont,
            ...colorToThemeTokens('primary', hostThemeConfig.theme.light.colorPrimary ?? buzzeasyThemeColor),
            ...colorToThemeTokens('info', hostThemeConfig.theme.light.colorInfo ?? hostThemeConfig.theme.light.colorPrimary ?? buzzeasyThemeColor),
          },
          algorithm: theme.defaultAlgorithm,
        },
        dark: {
          token: {
            ...hostThemeConfig.theme.dark,
            fontFamily: hostThemeConfig.theme.dark?.fontFamily
              ?? hostThemeConfig.theme.light.fontFamily
              ?? buzzeasyDefaultFont,
            colorPrimary: hostThemeConfig.theme.dark?.colorPrimary
              ?? hostThemeConfig.theme.light.colorPrimary
              ?? buzzeasyThemeColor,
            colorInfo: hostThemeConfig.theme.dark?.colorInfo
              ?? hostThemeConfig.theme.dark?.colorPrimary
              ?? hostThemeConfig.theme.light.colorInfo
              ?? hostThemeConfig.theme.light.colorPrimary
              ?? buzzeasyThemeColor,
          },
          algorithm: theme.darkAlgorithm,
        },
      };
    },
    [hostThemeConfig],
  );

  const themeConfig = (hostThemeSet ?? appTheme)[actualColorScheme];

  return (
    <AntDesignConfigContext.Provider value={themeConfig}>
      <ConfigProvider theme={themeConfig} locale={antLocales[i18n.language]}>
        {children}
      </ConfigProvider>
    </AntDesignConfigContext.Provider>
  );
}