import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';

export default function globalSetup(): void {
  dayjs.extend(updateLocale);
  tryPreventTabSleepWithWebLock();
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
let lockResolver: unknown;

function tryPreventTabSleepWithWebLock() {
  if (navigator && navigator.locks && navigator.locks.request) {
    const promise = new Promise((res) => { lockResolver = res; });
    navigator.locks.request('sleep-preventer-lock', { mode: 'shared' }, () => promise);
    // eslint-disable-next-line no-console
    console.log('Web Locks API supported, tab sleep prevention enabled.');
  }
  else {
    // eslint-disable-next-line no-console
    console.log('Web Locks API not supported, tab sleep prevention disabled.');
  }
}