import { generate } from '@ant-design/colors';
import { capitalize } from '@buzzeasy/shared-frontend-utilities';
import { GlobalToken } from 'antd';
import { SpaceSize } from 'antd/es/space';
import { AliasToken } from 'antd/es/theme/internal';

export function spaceSizeToNumber(tokens: GlobalToken, spaceSize: NonNullable<SpaceSize>): number {
  if (typeof spaceSize === 'number')
    return spaceSize;

  switch (spaceSize) {
    case 'large':
      return tokens.paddingLG;
    case 'middle':
      return tokens.padding;
    case 'small':
      return tokens.paddingXS;
  }
}

type ColorType = 'primary' | 'success' | 'warning' | 'error' | 'info';

const tokenSlots = ['Bg', 'BgHover', 'Border', 'BorderHover', 'Hover', '', 'Active', 'TextHover', 'Text', 'TextActive'] as const;
const getTokenName = (type: ColorType, slot: (typeof tokenSlots)[number]) => `color${capitalize(type)}${slot}` as keyof AliasToken;

export function colorToThemeTokens(type: ColorType, color: string): Partial<AliasToken> {
  const palette = generate(color);

  const res = {} as Record<string, string>;

  for (let i = 0; i < tokenSlots.length; i++) {
    res[getTokenName(type, tokenSlots[i])] = palette[i];
  }

  return res as Partial<AliasToken>;
}