import { AgentHubEventsContext } from '@buzzeasy/shared-frontend-utilities/agentHubEvents';
import { createContext, PropsWithChildren, ReactElement, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

interface GlobalActivityInfo {
  totalActivityCount: number;
  logNewActivityFor(key: string): void;
  markAllActivitiesAsRead(): void;
  updateActiveItem(key: string): void;
}

interface ActivityInfo {
  activityCount: number;
  markActivityAsRead(): void;
}

export const activityOverflowCount = 99;

export type ActivityInfoContextValue = GlobalActivityInfo & Record<string, ActivityInfo | undefined>;

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const ActivityInfoContext = createContext<ActivityInfoContextValue>(undefined!);

/**
 * Provides an API for managing activities for workItem tabs.
 *
 * **Provider dependencies:**
 * - AgentHubEventsProviderWrapper
 */
export default function ActivityInfoProvider({ children }: PropsWithChildren): ReactElement {
  const { subscribe, unsubscribe } = useContext(AgentHubEventsContext);

  const [activityCounts, setActivityCounts] = useState<Record<string, number>>({});
  const [activeItem, setActiveItem] = useState<string | null>(null);

  const activeItemRef = useRef(activeItem);
  activeItemRef.current = activeItem;

  const logNewActivityFor = useCallback(
    (key: string) => {
      if (key !== activeItemRef.current)
        setActivityCounts(curr => ({ ...curr, [key]: (curr[key] ?? 0) + 1 }));
    },
    [],
  );

  const clearActivityFor = useCallback(
    (key: string) => setActivityCounts(curr => {
      const copy = { ...curr };
      delete copy[key];
      return copy;
    }),
    [],
  );

  const markAllActivitiesAsRead = useCallback(
    () => setActivityCounts({}),
    [],
  );

  const updateActiveItem = useCallback(
    (key: string) => {
      setActiveItem(key);
      clearActivityFor(key);
    },
    [clearActivityFor],
  );

  // clear activity when workItem is closed
  useEffect(
    () => {
      const subId = subscribe({
        OnWorkItemClosed: ({ id }) => clearActivityFor(id),
      });
      return () => unsubscribe(subId);
    },
    [clearActivityFor, subscribe, unsubscribe],
  );

  const contextValue = useMemo(
    () => {
      const result: ActivityInfoContextValue = {
        totalActivityCount: Object.values(activityCounts).reduce((sum, count) => sum + count, 0),
        logNewActivityFor,
        markAllActivitiesAsRead,
        updateActiveItem,
      } as ActivityInfoContextValue; // TS needs this cast because it is stupid

      for (const key of Object.keys(activityCounts)) {
        result[key] = {
          activityCount: activityCounts[key],
          markActivityAsRead: () => clearActivityFor(key),
        };
      }

      Object.assign(window, { logNewActivityFor, markAllActivitiesAsRead });

      return result;
    },
    [activityCounts, clearActivityFor, logNewActivityFor, markAllActivitiesAsRead, updateActiveItem],
  );

  return (
    <ActivityInfoContext.Provider value={contextValue}>
      {children}
    </ActivityInfoContext.Provider>
  );
}