import { getItem, setItem, useAsyncMemo } from '@buzzeasy/shared-frontend-utilities';
import { ThemeConfig } from 'antd';
import { PropsWithChildren, ReactElement, createContext, useMemo } from 'react';
import environmentConfig from '../../../environmentConfig';
import FullScreenSpinner from '../../presenters/FullScreenSpinner';

const { environment, themeRepoUrl } = environmentConfig;

interface HostThemeConfig {
  appName: string;
  logoUrlColored: string;
  logoUrlWhite: string;
  faviconUrl: string;
  theme: {
    light: NonNullable<ThemeConfig['token']>;
    dark?: ThemeConfig['token'];
  };
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const HostThemeConfigContext = createContext<HostThemeConfig | null>(undefined!);

export default function HostThemeConfigProvider({ children }: PropsWithChildren): ReactElement {

  const configOverride = useMemo(
    () => {
      if (environment === 'prod')
        return null;

      return getItem<HostThemeConfig>('aui-themeConfigOverride');
    },
    [],
  );

  const savedConfig = useMemo(
    () => getItem<HostThemeConfig>('aui-cachedThemeConfig'),
    [],
  );

  const hostThemeConfig = useAsyncMemo(
    async () => {
      try {
        const resp = await fetch(`${themeRepoUrl}/${location.hostname}.json`);
        const config = resp.ok ? await resp.json() as HostThemeConfig : null;

        if (config)
          setItem('aui-cachedThemeConfig', config);

        return config;
      }
      catch {
        return null;
      }
    },
    [],
  );

  const usedConfig = hostThemeConfig ?? savedConfig;

  const useConfigWithOverrides = useMemo(
    () => usedConfig ? ({ ...usedConfig, ...configOverride }) : null,
    [configOverride, usedConfig],
  );

  // hostThemeConfig is undefined when the fetch is still pending
  if (hostThemeConfig === undefined && savedConfig === null)
    return <FullScreenSpinner />;

  return (
    <HostThemeConfigContext.Provider value={useConfigWithOverrides}>
      {children}
    </HostThemeConfigContext.Provider>
  );
}