import { PropsWithChildren, ReactElement } from 'react';
import AntDesignThemeProvider from './AntDesignThemeProvider';
import HostThemeConfigProvider from './HostThemeConfigProvider';
import StyledThemeProvider from './StyledThemeProvider';

/**
 * Handles the Ant Design and styled-components theme providers.
 *
 * **Provider dependencies:**
 * - SettingsProvider
 */
export default function ThemeProviders({ children }: PropsWithChildren): ReactElement {

  return (
    <HostThemeConfigProvider>
      <AntDesignThemeProvider>
        <StyledThemeProvider>
          {children}
        </StyledThemeProvider>
      </AntDesignThemeProvider>
    </HostThemeConfigProvider>
  );
}
