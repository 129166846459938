import { getItem } from '@buzzeasy/shared-frontend-utilities';
import i18next, { i18n } from 'i18next';
import { PropsWithChildren, ReactElement } from 'react';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import { translations } from '../../translations';
import { languageLocalStorageKey } from './SettingsProvider';

const rootI18nextInstance = i18next.createInstance();

rootI18nextInstance
  .use(initReactI18next)
  .init({
    resources: translations as unknown as Parameters<(typeof i18next)['init']>[0]['resources'],
    lng: getItem<string>(languageLocalStorageKey, 'en-US'),
    fallbackLng: 'en-US',
    interpolation: {
      escapeValue: false,
    },
  });

/**
 * Provides the i18next context.
 *
 * **No provider dependencies**
 */
export default function TranslationProvider({ children }: PropsWithChildren): ReactElement {

  return (
    <I18nextProvider i18n={rootI18nextInstance as i18n}>
      {children}
    </I18nextProvider>
  );
}