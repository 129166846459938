import { createGlobalStyle, css } from 'styled-components';

const scrollbarBaseStyles = css`
  background-clip: content-box;
  border: ${p => p.theme.paddingXXS}px solid transparent;
  border-radius: 1000000px;
`;

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${p => p.theme.fontFamily};
  }

  code {
    font-family: ${p => p.theme.fontFamilyCode};
  }

  // this annoys me to no end...
  .ant-select-status-warning .ant-select-selector {
    border-color: ${p => p.theme.colorWarning} !important;
  }

  ::-webkit-scrollbar {
    width: ${p => p.theme.size}px;
    height: ${p => p.theme.size}px;
  }

  ::-webkit-scrollbar-thumb {
    ${scrollbarBaseStyles}
    background-color: ${p => p.theme.colorPrimary};

    :hover {
      background-color: ${p => p.theme.colorPrimaryHover};
    }
  }

  ::-webkit-scrollbar-track {
    ${scrollbarBaseStyles}
    background-color: ${p => p.theme.colorPrimaryBg};
  }
`;

export default GlobalStyle;